<script lang="ts" setup>
import { computed, useSlots } from 'vue'
import Icon from '~/components/molecules/Icon.vue'
import type { IKeyValue } from '~/src/types/KeyValue'
import { hasOwnProperty } from '~/src/utils'
import { CheckboxType } from '~/src/enums/Checkbox'
import Tooltip from '~/components/molecules/Tooltip.vue'

type ModelTypes = string[] | boolean | IKeyValue[]
type ArrayOnly = Extract<ModelTypes, any[]>

interface Props {
  label?: string
  name: string
  type?: CheckboxType
  disabled?: boolean
  keyValue?: boolean
}

const model = defineModel<ModelTypes>()

const props = withDefaults(defineProps<Props>(), {
  type: CheckboxType.Primary,
})

const updateStringArray = (arrModel: string[]) => {
  const index = arrModel.indexOf(props.name)

  if (index === -1) {
    return [...arrModel, props.name]
  }
  else {
    return arrModel.filter(item => item !== props.name)
  }
}

const updateKeyValueArray = (arrModel: IKeyValue[]) => {
  const index = arrModel.findIndex(i => i.key === props.name)

  if (index === -1) {
    return [...arrModel, { key: props.name, value: props.label! }]
  }
  else {
    return arrModel.filter(item => item.key !== props.name)
  }
}

const check = () => {
  if (props.disabled) { return }
  if (isBoolean) {
    model.value = !model.value
  }
  else {
    let arrModel = model.value as ArrayOnly

    if (props.keyValue) {
      arrModel = updateKeyValueArray(arrModel as IKeyValue[])
    }
    else {
      arrModel = updateStringArray(arrModel as string[])
    }

    model.value = arrModel
  }
}

const isBoolean = typeof model.value === 'boolean'

const value = computed(() => {
  if (isBoolean) { return model.value as boolean }
  const arrModel = model.value as ArrayOnly

  if (typeof arrModel[0] === 'string') {
    return (arrModel as string[]).includes(props.name)
  }
  else {
    return (arrModel as IKeyValue[]).findIndex(i => i.key === props.name) !== -1
  }
})

const slots = useSlots()

const hasDescription = hasOwnProperty(slots, 'description')
</script>

<template>
  <div
    class="checkbox rounded w-fit text-button items-center grid select-none cursor-pointer"
    :class="[`checkbox--${props.type}`, value ? 'checkbox--checked' : 'checkbox--unchecked', props.disabled ? 'checkbox--disabled hover:cursor-default' : '']"
    @click="check"
  >
    <Icon
      :name="value ? 'filled-check' : 'check'"
      :filled="value"
      :stroked="!value"
    />
    <Tooltip
      v-if="label"
      :content="props.label!"
      class="text-ellipsis whitespace-nowrap overflow-hidden"
      on-overflow
    >
      <label class="text-ellipsis whitespace-nowrap overflow-hidden">{{ props.label }}</label>
    </Tooltip>
    <p
      v-show="hasDescription"
      class="col-start-2 text-body-regular-12 text-black-75"
    >
      <slot name="description" />
    </p>
  </div>
</template>

<style lang="postcss" scoped>
.checkbox {
  grid-template-columns: min-content auto;
  &--primary {
    @apply py-2 pl-2 pr-3 gap-x-2 gap-y-0.5;
    @apply border border-solid border-black-10 shadow-small-gray bg-white-100;

    :deep(.nuxt-icon) {
      @apply text-black-50;
    }

    &.checkbox--checked {
      @apply shadow-small-red border-accent bg-rose text-accent;

      :deep(.nuxt-icon) {
        @apply text-accent;
      }

      p {
        @apply text-dark-accent;
      }
    }

    &.checkbox--disabled {
      @apply shadow-transparent bg-black-1 border-black-10 text-black-100;

      label {
        opacity: 0.42;
      }

      :deep(.nuxt-icon) {
        @apply text-black-50;
      }

      p {
        opacity: 0.42;
        @apply text-black-100;
      }
    }
  }
  &--secondary {
    @apply py-0.5 gap-0.5 text-other;

    :deep(.nuxt-icon) {
      @apply text-black-50;
    }

    &.checkbox--checked {
      :deep(.nuxt-icon) {
        @apply text-accent;
      }
    }

    &.checkbox--disabled {
      @apply shadow-transparent border-black-10 text-black-100;

      label, p {
        opacity: 0.42;
      }

      :deep(.nuxt-icon) {
        @apply text-black-20;
      }

      &.checkbox--checked {
        :deep(.nuxt-icon) {
          @apply text-black-50;
        }
      }
    }
  }
}
</style>
